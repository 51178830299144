<template>
    <v-app>
        
    </v-app>
</template>

<script>
export default {
    name: 'HomePage',
    mounted(){
        window.location.href = 'https://nagendaadm.vercel.app/'
    }
}
</script>

<style scoped>

</style>