<template>
    <v-app style="background-color: rgb(250, 250, 250);">
        <v-container v-if="loading">
            <div class="load-container load1">
                <div class="loader">Carregando</div>
            </div>
        </v-container>
        <v-container v-else>
            <v-row>
                <v-col md="6" offset-md="3">
                    <div class="card-detalhes">
                        <v-row>
                            <v-col cols="12" style="text-align: center;">
                                <i class="fa-regular fa-circle-check" style="font-size: 32pt;color: rgb(40, 155, 40)" v-if="dados.status == 'Confirmado'"></i>
                                <i class="fa-regular fa-circle-xmark" style="font-size: 32pt;color: rgb(255, 136, 0)" v-if="dados.status == 'Cancelado'"></i>
                                <h3 v-if="dados.status == 'Confirmado'">Detalhes do agendamento</h3>
                                <h3 v-if="dados.status == 'Cancelado'">Agendamento cancelado</h3>

                                <hr>
                            </v-col>
                            <v-col cols="12">
                                <div class="infomacoes">
                                    <span>Quando</span>
                                    <p>{{ dados.data }} às {{ dados.hora.substr(0, 5) }}</p>
                                </div>
                                <div class="infomacoes">
                                    <span>O quê</span>
                                    <p>{{ dados.servico }}</p>
                                </div>
                                <div class="infomacoes">
                                    <span>Duração</span>
                                    <p>{{ dados.duracao }} min</p>
                                </div>
                                <div class="infomacoes">
                                    <span>Profissional</span>
                                    <p>{{dados.profissional}}</p>
                                </div>
                                <div class="infomacoes">
                                    <span>Status</span>
                                    <p>{{dados.status}}</p>
                                </div>
                                <div class="infomacoes">
                                    <span>Código</span>
                                    <p>{{ dados.codigo }}</p>
                                </div>

                                <hr>
                            </v-col>
                            <v-col>
                                <v-btn
                                    class="mt-5"
                                    text
                                    color="red"
                                    block
                                    v-if="dados.status == 'Confirmado'"
                                    @click="cancelaAgendamento"
                                >
                                    Cancelar Agendamento
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
    name: 'Agenda',
    data(){
        return{
            loading: true, 
            dados: ''
        }
    },
    async mounted(){
        await this.listaDados();
    },
    methods: {
        async insereLog(descricao, id_profissional = null, id_empresa = null, id_servico = null, id_agendamento = null){
            try {
                let ad = await axios.get('https://api.ipify.org?format=json')
                let data = {
                    descricao: `${ad.data.ip} - ${descricao}`,
                    id_profissional,
                    id_empresa,
                    id_servico,
                    id_agendamento,
                    ua: navigator.userAgent
                }
                await axios.post(`${config.baseUrl}logs`, data);
            } catch (error) {
                console.log(error)
            }
        },
        async listaDados(){
            try {
                this.loading = true;
                let response = await axios.get(`${config.baseUrl}agendamentos/${this.$route.params.hash}`)
                await this.insereLog(`Acessou detalhes do agendamento ${this.$route.params.hash}`, null, null, null, response.data.id)
                this.dados = response.data
                this.loading = false;
            } catch (error) {
                console.log(error)
            }
        },
        async cancelaAgendamento(){
            try {
                if (confirm("Tem certeza que deseja cancelar o agendamento?") == true) {
                    await axios.delete(`${config.baseUrl}agendamentos/${this.dados.codigo}`)
                    await this.insereLog(`Cliente cancelou o agendamento ${this.dados.codigo}`, null, null, null, this.dados.id)

                    await this.listaDados();
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>
    .card-detalhes{
        background-color: #fff;
        width: 100%;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #d4d4d4
    }
    .load-container {
    position: relative;
    width: 240px;
    height: 240px;
    margin: 0 auto;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    box-sizing: border-box;
    }

    .load-container:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 103px;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    left: 0;
    right: 0;
    color: #ff9100;
    box-sizing: border-box;
    }

    .load1 .loader {
    border-radius: 50%;
    font-size: 12px !important;
    width: 6em;
    height: 6em;
    margin: 7em auto;
    position: relative;
    text-indent: -9999em;
    border: 0.5em solid #ff910044;
    border-left: 0.5em solid #ff9100;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    -moz-transform: translateZ(0);
    animation: load 0.85s infinite linear;
    -o-animation: load 0.85s infinite linear;
    -webkit-animation: load 0.85s infinite linear;
    -moz-animation: load 0.85s infinite linear;
    }

    @-ms-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-moz-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-webkit-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-o-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }
</style>