<template>
    <v-app :style="`background-color: ${infos.empresa.cor_primaria};`">
        <div class="loading" v-if="loading">
            <div class="load-container load1">
                <div class="loader">Carregando</div>
            </div>
        </div>
        <div class="body" v-else>
           <Modelo01 v-if="infos.empresa.modelo == 1"/>
           <Modelo02 v-if="infos.empresa.modelo == 2"/>
        </div>
        
    </v-app>
</template>

<script>
import axios from 'axios';
import Modelo01 from './Modelo01.vue';
import Modelo02 from './Modelo02.vue';
import config from '../config';
export default {
    name: 'LandingPage',
    components:{Modelo01, Modelo02},
    metaInfo(){
        return{
            title: this.infos.empresa.nome,
            htmlAttrs: {
                lang: 'pt-br',
                amp: true
            }
        }
    },
    data(){
        return{
            loading: true,
            slug: this.$route.params.slug,
            infos: {
                empresa: {
                    cor_primaria: '#fff'
                }
            }
        }
    },
    async mounted(){
        await this.getInitData();
    },
    methods: {
        async getInitData(){
            try {
                let response = await axios.get(`${config.baseUrl}empresas/${this.slug}`)
                this.infos = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style scoped>
    #btn-servico{
        color: #fff;
        margin-top: 6px;
        margin-bottom: 6px;
        padding: 0;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    .profissional{
        padding: 10px;
        text-align: center;
        border-radius: 10px;
    }
    
    .card-detalhes{
        background-color: rgb(255, 247, 247);
        width: 100%;
        padding: 15px;
        border-radius: 10px;
    }

    .nome-empresa{
        opacity: .8;
    }

    .nome-servico{
        color: #000;
        font-size: 18pt;
        font-weight: 500;
        padding-top: 0;
    }

    .infos{
        padding-top: 0;
    }

    .card-profissional{
        text-align: center;
        color: #fff;
        border-radius: 7px;
    }

    .profissional-foto .foto-p{
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0 auto;

    }

    .profissional-foto .foto-p img{
        display: block;
        vertical-align: middle;
        max-width: 100%;
        height: 100%;
        width: 100%;
        border-radius: 9999px;
        object-fit: cover;
        object-position: center;
    }

    .profissional-nome{
        font-weight: 450;
    }

    .listaHorarios{
        height: 300px;
        overflow: scroll;
    }

    

    .input-default{
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        border-radius: 5px;
        background-color: #fff;
    }



    .load-container {
    position: relative;
    width: 240px;
    height: 240px;
    margin: 0 auto;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    box-sizing: border-box;
    }

    .load-container:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 103px;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    left: 0;
    right: 0;
    color: #ff9100;
    box-sizing: border-box;
    }

    .load1 .loader {
        border-radius: 50%;
        font-size: 12px !important;
        width: 6em;
        height: 6em;
        margin: 7em auto;
        position: relative;
        text-indent: -9999em;
        border: 0.5em solid #ff910044;
        border-left: 0.5em solid #ff9100;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        -moz-transform: translateZ(0);
        animation: load 0.85s infinite linear;
        -o-animation: load 0.85s infinite linear;
        -webkit-animation: load 0.85s infinite linear;
        -moz-animation: load 0.85s infinite linear;
    }

    @-ms-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-moz-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-webkit-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-o-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }


</style>