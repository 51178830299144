<template>
    <v-app :style="`background-color: ${infos.empresa.corPrimaria};`">
        <div class="loading" v-if="loading">
            <div class="load-container load1">
                <div class="loader">Carregando</div>
            </div>
        </div>
        <div class="body" v-else>
            <v-dialog
                v-model="mMinhasAgendas"
                max-width="600px"
            >
                <v-card>
                <v-card-title style="text-align: center;">
                    Consultar Agendas
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <input type="text" id="telefone" v-mask="['(##) #####-####']" v-model="telefoneBusca" :style="`border: 1px solid ${infos.empresa.corPrimaria};color: ${infos.empresa.corTexto2};font-size: 14pt`" placeholder="Telefone" class="input-default">
                            </v-col>
                            <v-col cols="12">
                                <v-btn
                                outlined
                                :color="infos.empresa.corBotoes"
                                block
                                @click="buscarAgendas"
                                v-if="btnLoading2 == false"
                                >
                                    Buscar
                                </v-btn>
                                <v-btn
                                outlined
                                :color="infos.empresa.corBotoes"
                                block
                                disabled
                                v-if="btnLoading2"
                                >
                                    Aguarde...
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-for="a in agendas" :key="a.id">
                            <v-col cols="12">
                                <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                    <i class="fa-solid fa-briefcase"></i> <strong>{{  a.servico.nome }}</strong>
                                </div>
                                <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                    <i class="fa-solid fa-user"></i> <strong>Cliente: {{  a.nomeCliente }}</strong>
                                </div>
                                <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                    <i class="fa-regular fa-user"></i>  {{  a.profissional.nome }}
                                </div>
                                <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                    <i class="fa-regular fa-clock"></i>  {{  a.servico.tempo }} min
                                </div>
                                <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                    <i class="fa-solid fa-tag"></i> R$ {{  a.servico.valor }}
                                </div>
                                <strong :style="`color: ${infos.empresa.corTexto2};font-size: 10pt;`"><i class="fa-regular fa-calendar"></i> {{ a.dataExtenso }} às {{ a.hora }}</strong>
                                <v-btn
                                outlined
                                :color="infos.empresa.corBotoes"
                                block
                                @click="verAgendamento(a.hash)"
                                >
                                    Abrir
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-btn
                    :color="infos.empresa.corTexto2"
                    text
                    @click="mMinhasAgendas = false"
                    >
                    Cancelar
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
            <v-container v-if="servicoSelecionado.length == 0 && profissional.length == 0 && horarioSelecionado == 0">
                <v-row style="padding: 0 5px 0 5px;">
                    <v-col md="6" offset-md="3" style="padding: 5px 0 5px;">
                        <div @click="abrirConsultaAgendas" id="btn-agendas" :style="`background-color: ${infos.empresa.corSecundaria};color: ${infos.empresa.corTexto}; `">
                            Minhas Agendas
                        </div>
                    </v-col>
                </v-row>
                <v-row style="padding: 0 5px 0 5px;">
                    <v-col md="6" offset-md="3" style="padding: 0;">
                        <img :src="infos.empresa.imagemTopo" style="width: 100%;" alt="">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="6" offset-md="3">
                        <v-row>
                            <v-col v-for="p in profissionais" :key="p.id" @click="selecionaProfissional(p)">
                                <div class="card-profissional" :style="`background-color: ${infos.empresa.corSecundaria};color: ${infos.empresa.corTexto};`">
                                    <v-row>
                                        <v-col cols="12" class="profissional-foto">
                                            <div class="foto-p">
                                                <img :src="p.foto" alt="">
                                            </div>
                                        </v-col>
                                        <v-col cols="12" class="profissional-nome" style="margin: 0; padding: 0;margin-bottom: 10px;">
                                            {{ p.nome }}
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>


            <v-container v-if="profissional.length != 0 && horarioSelecionado == 0 && servicoSelecionado.length == 0">     
                <v-row>
                    <v-col md="6" offset-md="3">
                        <div class="card-detalhes"  :style="`background-color: ${infos.empresa.corCard};`">
                            <v-row>
                                <v-col style="margin: 0;padding: 0;">
                                    <v-btn
                                        text
                                        :color="infos.empresa.corSecundaria"
                                        @click="profissional = []"
                                        :style="`color: ${infos.empresa.corBotoes};`"
                                    >
                                    <i class="fa-solid fa-arrow-left"></i> Voltar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="nome-empresa" :style="`color: ${infos.empresa.corTexto2};`">
                                    {{ infos.empresa.nome }}
                                </v-col>
                                <v-col cols="12" class="infos">
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-regular fa-user"></i>  {{  profissional.nome }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" :style="`color: ${infos.empresa.corTexto2};`">
                                    Selecione um serviço
                                </v-col>
                            </v-row>
                            <v-row v-for="s in servicos" :key="s.id" @click="selecionaServico(s)" style="padding: 0 5px 0 5px;">
                                <v-col id="btn-servico" :style="`background-color: ${infos.empresa.corSecundaria};color: ${infos.empresa.corTexto};`">
                                    <v-row>
                                        <v-col cols="9">{{ s.nome }}</v-col>
                                        <v-col cols="3" style="font-size: 13px;text-align: right;">{{ s.tempo }} min</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-if="servicoSelecionado.length != 0 && profissional.length != 0 && horarioSelecionado == 0">     
                <v-row>
                    <v-col md="6" offset-md="3">
                        <div class="card-detalhes" :style="`background-color: ${infos.empresa.corCard};`">
                            <v-row>
                                <v-col style="margin: 0;padding: 0;">
                                    <v-btn
                                        text
                                        :color="infos.empresa.corSecundaria"
                                        @click="servicoSelecionado = [];"
                                        :style="`color: ${infos.empresa.corBotoes};`"
                                    >
                                    <i class="fa-solid fa-arrow-left"></i> Voltar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="nome-empresa" :style="`color: ${infos.empresa.corTexto2};`">
                                    {{ infos.empresa.nome }}
                                </v-col>
                                <v-col cols="12" class="nome-servico" :style="`color: ${infos.empresa.corTexto2};`">
                                    {{ servicoSelecionado.nome }}
                                </v-col>
                                <v-col cols="12" class="infos">
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-regular fa-user"></i>  {{  profissional.nome }}
                                    </div>
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-regular fa-clock"></i>  {{  servicoSelecionado.tempo }} min
                                    </div>
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-solid fa-tag"></i> R$ {{  servicoSelecionado.valor }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <vc-date-picker
                                        mode="date"
                                        ref="calendario"
                                        v-model="data"
                                        :minute-increment="5"
                                        :model-config="modelConfig"
                                        @dayclick="onDayClick"
                                        :min-date="new Date()"
                                        is-expanded
                                        />
                                </v-col>
                                <v-col cols="12">
                                    <div class="loadingHorario" v-if="loadingHorarios">
                                        <div class="load-container load1">
                                            <div class="loader">Carregando</div>
                                        </div>
                                    </div>
                                    <div class="horarioL" v-else>
                                        <div class="listaHorarios" v-if="horariosDisponiveis != 0 && aberto == 1">
                                            <v-btn
                                                class="mb-2"
                                                outlined
                                                :color="infos.empresa.corBotoes"
                                                block
                                                v-for="h in horariosDisponiveis"
                                                :key="h"

                                                @click="selectHour(h)"
                                            >
                                            {{ h }}
                                            </v-btn>
                                        </div>
                                        <div v-if="aberto == 0" style="text-align: center;">
                                            <i class="fa-regular fa-calendar-xmark" style="font-size: 40pt;"></i>
                                            <h4 style="text-align: center;">Nenhum horário para esta data.</h4>
                                        </div>
                                    </div>
                                    
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-if="horarioSelecionado != 0">     
                <v-row>
                    <v-col md="6" offset-md="3">
                        <div class="card-detalhes">
                            <v-row>
                                <v-col style="margin: 0;padding: 0;">
                                    <v-btn
                                        text
                                        :color="infos.empresa.corSecundaria"
                                        @click="horarioSelecionado = 0;initCountTime()"
                                        :style="`color: ${infos.empresa.corBotoes};`"
                                    >
                                    <i class="fa-solid fa-arrow-left"></i> Voltar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="nome-empresa" :style="`color: ${infos.empresa.corTexto2};`">
                                    {{ infos.empresa.nome }}
                                </v-col>
                                <v-col cols="12" class="nome-servico" :style="`color: ${infos.empresa.corTexto2};`">
                                    {{ servicoSelecionado.nome }}
                                </v-col>
                                <v-col cols="12" class="infos">
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-regular fa-user"></i>  {{  profissional.nome }}
                                    </div>
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-regular fa-clock"></i>  {{  servicoSelecionado.tempo }} min
                                    </div>
                                    <div class="" :style="`color: ${infos.empresa.corTexto2};`">
                                        <i class="fa-solid fa-tag"></i> R$ {{  servicoSelecionado.valor }}
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                <strong :style="`color: ${infos.empresa.corTexto2};`">Suas informações</strong><br>
                                <strong :style="`color: ${infos.empresa.corTexto2};font-size: 10pt;`"><i class="fa-regular fa-calendar"></i> {{ dataHoraMostrar }}</strong>
                                </v-col>
                                <v-col cols="12">
                                    <label for="nome" :style="`color: ${infos.empresa.corTexto2};`">Seu Nome</label>
                                    <input type="text" id="nome" v-model="nome" :style="`border: 1px solid ${infos.empresa.corPrimaria};color: ${infos.empresa.corTexto2};`" placeholder="Seu Nome" class="input-default">
                                    <br>
                                    <br>

                                    <label for="telefone">Telefone</label>
                                    <input type="text" id="telefone"  v-mask="['(##) #####-####']" v-model="telefone" :style="`border: 1px solid ${infos.empresa.corPrimaria};color: ${infos.empresa.corTexto2};`" placeholder="Telefone" class="input-default">

                                    <br>
                                    <v-btn
                                        class="mt-5"
                                        outlined
                                        :color="infos.empresa.corBotoes"
                                        block
                                        @click="concluirAgendamento"
                                        v-if="btnLoading == false"
                                    >
                                        Concluir Agendamento
                                    </v-btn>
                                    <v-btn
                                        class="mt-5"
                                        outlined
                                        :color="infos.empresa.corBotoes"
                                        block
                                        disabled
                                        v-if="btnLoading"
                                    >
                                        Aguarde...
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        
    </v-app>
</template>

<script>
import axios from 'axios';
import {mask} from 'vue-the-mask'
import hash from 'hash-generator'
import moment from 'moment'
import config from '../config';

var countTimeInterval;


function verificaCelular(celular){
    celular =  celular.replace('/[^0-9]/', '');
    return celular.match(/^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/);
}
export default {
    name: 'Modelo02',
    directives: {mask},
    metaInfo(){
        return{
            title: this.infos.empresa.nome,
            htmlAttrs: {
                lang: 'pt-br',
                amp: true
            }
        }
    },
    data(){
        return{
            loading: true,
            mMinhasAgendas: false,
            loadingHorarios: false,
            slug: this.$route.params.slug,
            infos: {
                empresa: {
                    corPrimaria: '#fff'
                }
            },
            btnLoading: false,
            btnLoading2: false,
            data: new Date(),
            servicos: [],
            servicosTemp: [],
            profissionais: [],
            widthTopoImg: 400,
            servicoSelecionado: [],
            profissional: [],
            dataSelecionadaMostrar: '',
            funcionamento: [],
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
            aberto: 1,
            attributes: [
                {
                    content: 'red',
                }
            ],
            horariosDisponiveis: 0,
            horarioSelecionado: 0,
            dataSelecionada: '',
            dataHoraMostrar: '',
            nome: '',
            telefone: '',
            telefoneBusca: '',
            agendas: [],
            agendado: 0,
            countTime: 0,
            eventoCalendario: {},
        }
    },
    async mounted(){
        await this.getInitData();
        moment.locale("pt-br")
    },
    watch: {
        async countTime(){
            if(this.countTime == 6){
                if(this.profissional.length != 0 && this.horarioSelecionado == 0){
                    await this.onDayClick(this.eventoCalendario, false);
                }
            }
        }
    },
    methods: {
        async initCountTime(){
            clearInterval(countTimeInterval);
            this.countTime = 0;
            countTimeInterval = setInterval(() => {
                this.countTime++
            }, 1000);
        },
        async stopCountTime(){
            this.countTime = 0;
            clearInterval(countTimeInterval);
        },
        async insereLog(descricao, id_profissional = null, id_empresa = null, id_servico = null, id_agendamento = null){
            try {
                let ad = await axios.get('https://api.ipify.org?format=json')
                let data = {
                    descricao: `${ad.data.ip} - ${descricao}`,
                    id_profissional,
                    id_empresa,
                    id_servico,
                    id_agendamento,
                    ua: navigator.userAgent
                }
                await axios.post(`${config.baseUrl}logs`, data);
            } catch (error) {
                console.log(error)
            }
        },
        async getInitData(){
            try {
                let response = await axios.get(`${config.baseUrl}empresas/${this.slug}`)
                this.infos = response.data;
                this.insereLog(`Acessou a página inicial da empresa ${this.infos.empresa.nome}`, null, this.infos.empresa.id);
                this.servicosTemp = response.data.servicos.map(x => {
                    if(x.profissionais != 'TODOS'){
                        x.profissionais = typeof x.profissionais == 'string' ? JSON.parse(x.profissionais) : x.profissionais;
                    }
                    return x
                });
                this.profissionais = response.data.profissionais;
                this.funcionamento = response.data.funcionamento;
                this.attributes[0].content = response.data.empresa.corSecundaria
                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
        async abrirConsultaAgendas(){
            this.mMinhasAgendas = true;
            this.insereLog(`Abriu a consulta de agendas`, null, this.infos.empresa.id);
        },
        async buscarAgendas(){
            
            try {
            
                if(!this.telefoneBusca){
                    alert('Insira um telefone!');
                    this.telefoneBusca = '';
                    return false;
                }
                if(!verificaCelular(this.telefoneBusca.replace(' ', '').replace('-', '').replace('(', '').replace(')', ''))){
                    alert('Telefone inválido!');
                    this.telefoneBusca = '';
                    return false;
                }

                this.btnLoading2 = true;

                let response = await axios.get(`${config.baseUrl}minhasagendas/${this.telefoneBusca.replace(' ', '').replace('-', '').replace('(', '').replace(')', '')}/${this.infos.empresa.id}`)
                this.insereLog(`Buscou agendas do telefone: ${this.telefoneBusca}`, null, this.infos.empresa.id);
                
                if(response.data.length > 0){
                    this.agendas = response.data;
                    this.telefoneBusca = '';
                    this.btnLoading2 = false;
                }else{
                    alert("Nenhum agendamento encontrado!")
                    this.telefoneBusca = '';
                    this.btnLoading2 = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async verAgendamento(hash){
            this.$router.push(`/${this.slug}/agenda/${hash}`);
        },
        async onDayClick(event, loading = true){
            try {
                this.eventoCalendario = event;
                await this.initCountTime();
                if(loading){
                    this.horariosDisponiveis = 0
                    this.loadingHorarios = true;
                }
                let data = {
                    data: event.id,
                    semana: event.weekday - 1,
                    profissional: this.profissional.id,
                    empresa: this.infos.empresa.id,
                    servico: this.servicoSelecionado.id
                }

                this.insereLog(`Listou horários disponiveis na data: ${event.id}`, this.profissional.id, this.infos.empresa.id, this.servicoSelecionado.id);

                let response = await axios.post(`${config.baseUrl}agendamentos/horarios`, data);
                this.dataSelecionadaMostrar = `${event.ariaLabel.charAt(0).toUpperCase()}${event.ariaLabel.slice(1)}`;
                this.dataSelecionada = event.id;
                this.horariosDisponiveis = response.data.horarios
                this.aberto = response.data.aberto
                this.horarioSelecionado = 0;
                this.loadingHorarios = false;
                if(response.data.horarios.length == 0){
                    this.aberto = 0;
                    this.horariosDisponiveis = 0;
                }
            } catch (error) {
                console.log(error)
            }
        },

        async selectHour(hora){
            try {
                this.horarioSelecionado = hora;
                this.dataHoraMostrar = `${this.dataSelecionadaMostrar} - ${hora}`
                this.insereLog(`Selecionou o horário: ${hora} da data ${this.dataSelecionada}`, this.profissional.id, this.infos.empresa.id, this.servicoSelecionado.id);

            } catch (error) {
                console.log(error);
            }
        },
        async validaWhatsapp(tel){
            try {
                let valida = await axios.get(`${config.baseUrl}validar/${tel}`);
                return valida.data
            } catch (error) {
                return error;
            }
        },
        async concluirAgendamento(){
            try {
                let { nome, telefone } = this
                if(!nome){
                    alert('Insira seu nome!');
                    return false;
                    
                }
                if(!telefone){
                    alert('Insira seu telefone!');
                    return false;
                }
                if(!verificaCelular(telefone.replace(' ', '').replace('-', '').replace('(', '').replace(')', ''))){
                    alert('Telefone inválido!');
                    return false;
                }

                

            this.btnLoading = true;

            let valida = await this.validaWhatsapp(telefone.replace(' ', '').replace('-', '').replace('(', '').replace(')', ''))
            console.log(valida)
                if(!valida[0].exists){
                    alert('O Telefone informado não está registrado no whatsapp!');
                    this.btnLoading = false;
                    return false;
                }



                let data = {
                    nome_cliente: nome,
                    telefone_cliente: telefone,
                    id_servico: this.servicoSelecionado.id,
                    id_profissional: this.profissional.id,
                    id_empresa: this.infos.empresa.id,
                    hash: hash(12),
                    status: 'ATIVO',
                    data: this.dataSelecionada,
                    hora: this.horarioSelecionado,
                    data_extenso: this.dataSelecionadaMostrar
                }
                
                let response = await axios.post(`${config.baseUrl}agendamentos/insere`, data);
                this.insereLog(`${nome} - ${telefone} - Realizou um agendamento ${this.dataSelecionadaMostrar}`, this.profissional.id, this.infos.empresa.id, this.servicoSelecionado.id, response.data.id);


                this.nome = '';
                this.telefone = '';

                this.$router.push(`/${this.slug}/agenda/${data.hash}`);

            } catch (error) {
                console.log(error)
            }
        },
        async selecionaServico(servico){
            this.servicoSelecionado = servico
            this.insereLog(`Selecionou o serviço: ${servico.nome}`, this.profissional.id, this.infos.empresa.id, servico.id);
            await this.onDayClick({id: moment().format('YYYY-MM-DD'), weekday: new Date().getDay()+1, ariaLabel: moment().format('dddd, D [de] MMMM [de] YYYY')})
        },
        async selecionaProfissional(profissional){
            this.servicos = []
            this.servicosTemp.map(x => {
                if(x.profissionais == 'TODOS'){
                    this.servicos.push(x)
                }else{
                    if(x.profissionais.includes(profissional.id)){
                        this.servicos.push(x)
                    }
                }
            })
            this.profissional = profissional

            this.insereLog(`Selecionou o profissional: ${profissional.nome}`, profissional.id, this.infos.empresa.id);
        }
    }
}
</script>

<style scoped>
    #btn-agendas{
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
        padding: 5px;
        text-align: center;
    }
    #btn-servico{
        color: #fff;
        margin-top: 6px;
        margin-bottom: 6px;
        padding: 0;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }
    .profissional{
        padding: 10px;
        text-align: center;
        border-radius: 10px;
    }
    
    .card-detalhes{
        background-color: rgb(255, 247, 247);
        width: 100%;
        padding: 15px;
        border-radius: 10px;
    }

    .nome-empresa{
        opacity: .8;
    }

    .nome-servico{
        color: #000;
        font-size: 18pt;
        font-weight: 500;
        padding-top: 0;
    }

    .infos{
        padding-top: 0;
    }

    .card-profissional{
        text-align: center;
        color: #fff;
        border-radius: 7px;
    }

    .profissional-foto .foto-p{
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        margin: 0 auto;

    }

    .profissional-foto .foto-p img{
        display: block;
        vertical-align: middle;
        max-width: 100%;
        height: 100%;
        width: 100%;
        border-radius: 9999px;
        object-fit: cover;
        object-position: center;
    }

    .profissional-nome{
        font-weight: 450;
    }

    .listaHorarios{
        height: 300px;
        overflow: scroll;
    }

    

    .input-default{
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        border-radius: 5px;
        background-color: #fff;
    }



    .load-container {
    position: relative;
    width: 240px;
    height: 240px;
    margin: 0 auto;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    box-sizing: border-box;
    }

    .load-container:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 103px;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    left: 0;
    right: 0;
    color: #ff9100;
    box-sizing: border-box;
    }

    .load1 .loader {
        border-radius: 50%;
        font-size: 12px !important;
        width: 6em;
        height: 6em;
        margin: 7em auto;
        position: relative;
        text-indent: -9999em;
        border: 0.5em solid #ff910044;
        border-left: 0.5em solid #ff9100;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        -moz-transform: translateZ(0);
        animation: load 0.85s infinite linear;
        -o-animation: load 0.85s infinite linear;
        -webkit-animation: load 0.85s infinite linear;
        -moz-animation: load 0.85s infinite linear;
    }

    @-ms-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-moz-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-webkit-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @-o-keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }

    @keyframes load {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg)
    }
    }


</style>